.home {
  display: flex;
  width: 100%;
  .homeContainer {
    flex: 6;
    .widgets,
    .charts {
      display: flex;
      padding: 10px 0;
      margin: 20px;
      gap: 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
    }
    .listTitle {
      font-weight: 500;
      color: gray;
      margin-bottom: 10px;
      padding: 10px;
    }
  }
}
