.sidebar {
  // flex: 1;
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;

  border-right: 0.5px solid lightgray;
  min-height: 100vh;
  background-color: #fff;
  padding: 0 20px;
  a {
    text-decoration: none;
  }
  .top {
    // height: 100px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #888;

      // display: block;
    }
    .logo-dim {
      color: #198754;
      font-weight: 300;
    }
  }
  hr {
    height: 0;
    border: 0.5px solid lightgray;
  }

  .center {
    padding: 50px 0;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      .title {
        font-size: 12px;
        color: #d8d8d8;
        margin-bottom: 15px;
        font-weight: bold;
      }

      li {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          background-color: #ccc;
          //   color: #fff;
        }

        .icon {
          font-size: 20px;
          color: #198754;
        }

        span {
          //   font-size: 14px;
          font-weight: 600;
          color: #888;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    // margin-top: auto;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #198754;
      cursor: pointer;
      margin-right: 10px;

      //   padding: 20px;
      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
    }
  }
}
footer {
  // // margin-top: 50px;
  // // align-items: flex-end;
  // display: flex;
  // align-self: flex-end;

  p {
    color: #19875498;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
}
