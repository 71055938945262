.navbar {
  height: 50px;
  border-bottom: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  .wrapper {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .items {
      display: flex;
      align-items: center;
      gap: 20px;
      .item {
        display: flex;
        align-items: center;
        position: relative;
        .icon {
          font-size: 20px;
          color: #198754;
        }
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .counter {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: red;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;
      input {
        border: none;
        outline: none;
        background: transparent;
        &::placeholder {
          font-size: 13px;
        }
      }
    }
  }
}
