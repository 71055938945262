.edit {
  width: 100%;
  display: flex;
  .newContainer {
    flex: 6;
    .top,
    .bottom {
      width: 80%;
      padding: 20px;
      margin: 20px auto;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        flex: 1;
        text-align: center;
        // align-items: center;
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
      }
      .right {
        border-left: 1px solid lightgray;
        padding: 10px;
        flex: 3;

        form {
          display: flex;
          // flex-wrap: wrap;
          flex-direction: column;
          gap: 20px;
          // justify-content: center;
          align-items: center;

          .formInput {
            width: 60%;
            margin-bottom: 10px;
            label {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              gap: 10px;
              .icon {
                cursor: pointer;
              }
            }
            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid lightgray;
              outline: none;
              background-color: rgba(228, 228, 228, 0.3);
            }
          }
          button {
            width: 150px;
            border: none;
            padding: 10px;
            background-color: rgb(25, 135, 84);
            color: rgb(255, 255, 255);
            border-radius: 5px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      h1 {
        color: lightgray;
        font-size: 25px;
      }
    }
  }
}
