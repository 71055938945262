.list {
	display: flex;
	width: 100%;
	.listContainer {
		flex: 6;
		.loading {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			color: #444;
			font-weight: bold;
		}
	}
}
