.chart {
  flex: 5;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  .title {
    color: lightgray;
  }
  .chartGrid {
    stroke: lightgray;
  }
  // .recharts-responsive-container {
  //   padding: 0 20px;
  // }
}
