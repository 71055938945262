.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
      margin-bottom: 10px;
      color: #444;

      span {
        color: #198754;
        // font-size: 13px;
        font-weight: 300;
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 25px;
      input {
        width: 300px;
        height: 30px;
        border: none;
        background-color: #f0f0f0;
        padding: 5px 10px;
        border-radius: 3px;
        &:focus {
          outline: none;
          border-bottom: 3px solid #198754;
        }
        &::placeholder {
          color: #8b8b8b;
        }
      }
      .password-toggle {
        position: absolute;
        top: 8px;
        right: 10px;
        z-index: 1000;
        cursor: pointer;
        color: #198754;
      }
    }
    // input[type='password']
    button {
      border: none;
      padding: 8px 0;
      background-color: #198754;
      border-radius: 5px;
      color: #fff;
      font-weight: bold;
      letter-spacing: 2px;
      width: 150px;

      cursor: pointer;
    }
    .error-msg {
      color: #960000;
      font-size: 13px;
      width: 300px;
      background-color: rgba(255, 0, 0, 0.3);
      padding: 10px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
