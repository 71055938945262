.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;
    .top {
      display: flex;
      padding: 20px;
      gap: 20px;
      .left {
        flex: 1;
        width: 100%;
        padding: 20px;

        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        position: relative;
        .title {
          font-size: 18px;
          color: lightgray;
          margin-bottom: 10px;
        }
        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          color: rgb(25, 135, 84);
          background-color: rgba(25, 135, 84, 0.3);
          border-radius: 0 0 0 5px;
          cursor: pointer;
        }
        .items {
          display: flex;
          gap: 20px;
          .itemImg {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
          .details {
            .detailTitle {
              margin-bottom: 10px;
              color: #555;
              //   font-size: 20px;
            }
            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;
              display: flex;
              gap: 10px;

              .detailItemKey {
                color: gray;
                font-weight: bold;
              }
              .detailItemValue {
                font-weight: 300;
              }
            }
          }
        }
      }
      .right {
        flex: 2;
      }
    }
    .bottom {
      //   width: 100%;
      padding: 20px;
      margin: 20px;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      .title {
        font-size: 18px;
        color: lightgray;
        margin-bottom: 10px;
      }
    }
  }
}
