.table {
  .tableHeader {
    font-weight: bold;
  }
  .status {
    padding: 5px;
    border-radius: 5px;

    &.Active {
      color: green;
      background-color: #00800046;
    }
    &.Due {
      color: orange;
      background-color: #ffa60049;
    }
    &.Overdue {
      color: red;
      background-color: #ff00004f;
    }
  }
}
