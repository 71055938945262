.datatable {
  height: 500px;
  padding: 20px;

  a {
    text-decoration: none;
  }
  .addItem {
    border: none;
    padding: 5px 15px;
    margin-bottom: 20px;
    background-color: rgb(25, 135, 84);
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cellWithStatus {
    padding: 10px;
    border-radius: 5px;
    &.Active {
      color: green;
      background-color: #00800046;
    }

    &.Inactive {
      color: red;
      background-color: #ff00004f;
    }
    &.onSchedule {
      color: green;
      background-color: #00800046;
    }
    &.overDue {
      color: red;
      background-color: #ff00004f;
    }
  }
  .cellAction {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    .viewButton {
      border-radius: 5px;
      padding: 3px 6px;
      color: rgb(0, 0, 255);
      border: 1px solid rgba(0, 0, 255, 0.3);
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 255, 0.7);
        color: #fff;
        font-weight: bold;
      }
    }
    .editButton {
      border-radius: 5px;
      padding: 3px 6px;
      color: rgb(0, 0, 255);
      border: 1px solid rgba(0, 0, 255, 0.3);
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 255, 0.7);
        color: #fff;
        font-weight: bold;
      }
    }
    .deleteButton {
      border-radius: 5px;
      padding: 3px 6px;
      color: rgb(220, 20, 60);
      border: 1px solid rgba(220, 20, 60, 0.3);
      cursor: pointer;
      &:hover {
        background-color: rgba(220, 20, 60, 0.7);
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
