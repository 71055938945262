.widget {
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: space-between;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  height: 100px;
  border-radius: 10px;
  //   gap: 10px;
  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      color: gray;
      align-self: center;
    }
    .counter {
      font-size: 28px;
      font-weight: 300;
      align-self: center;
    }
    .link {
      font-size: 12px;
      border-bottom: 0.5px solid gray;
      width: max-content;
      
      a{
        text-decoration: none;
        color: rgb(177, 177, 177);
      }
    }
    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;
      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }
    .icon {
      font-size: 18px;
      padding: 5px;
      background-color: #1987548f;
      color: #fff;
      border-radius: 5px;
      align-self: flex-end;
    }
  }
}
